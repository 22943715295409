<template>
  <div>
    <div class="header">
      <div>
        <el-select v-model="params.site_id" class="el-select">
          <el-option
            v-for="item in siteList"
            :key="item.site_id"
            :value="item.site_id"
            :label="item.proxy_name"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="getList">查询</el-button>
        <el-button type="primary" @click="syncInsertData">同步插入</el-button>
        <el-button type="primary" @click="syncUpdateData">同步更新</el-button>
      </div>
    </div>
    <DragTable :header="header" :list-query="list" :sort="true" @change="handle" @start-id="getId">
      <template #site="{row}">{{row.site.proxy_name}}</template>
      <template #trans="{row}">
        <el-input v-model="row.trans" @blur="editTrans(row)" />
      </template>
      <template #control="{row}">
        <div class="control">
          <span @click="edit(row)">编辑</span>
        </div>
      </template>
    </DragTable>
    <go-dialog v-model="show">
      <div>
        <h3>原站一级分类</h3>
        <p>{{categoryDetail.name}}</p>
        <h3>翻译</h3>
        <el-input v-model="categoryDetail.trans"  placeholder="请输入翻译文本"></el-input>
      </div>
      <footer style="display:flex;justify-content: space-between;margin-top:10px;">
        <el-button type="primary" @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'

export default defineComponent({
  setup(props,{ root }) {
    const categoryDetail = ref({})
    const show = ref(false)
    const siteList = ref([]);
    const params = reactive({
      children_category_id: '',
      level: 1,
      site_id: 19,
      parent_key: ''
    })
    const header = [
      { name: "序号", value: "id" },
      { name: "站点", slot: "site" },
      { name: "源站一级分类", value: "name" },
      { name: "翻译", slot: "trans" },
      { name: "操作", slot: 'control', width: 80 },
    ]
    const list = reactive({data: []})
    watch(show,val=>{
      if(!val) {
        getList()
      }
    })
    const getSite = () => {
      root.$axios
        .get("/orderSafe/platformBan/site/list")
        .then((res) => {
          siteList.value = res.data;
        })
        
    };
    getSite();
    const getList = () => {
      root.$axios.get('/category/siteSearch/index', {
        params
      })
      .then(res=>{
        list.data = res.data
      })
      
    }
    getList()
    const edit = data => {
      categoryDetail.value = data || {}
      show.value = true
    }
    const editTrans = data => {
      root.$axios.patch(`/category/siteSearch/update`,{
        id: data.id,
        trans: data.trans
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
      
    }
    const cancel = async ()=>{
      categoryDetail.value = {}
      show.value = false
    }
    const confirm = () => {
      root.$axios.patch(`/category/siteSearch/update`,{
        id: categoryDetail.value.id,
        trans: categoryDetail.value.trans
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
        cancel()
      })
      
    }
    const syncInsertData = () => {
      root.$axios.get(`/category/siteSearch/syncInsertData`)
      .then(res=>{
        root.$message.success(`影响行数：${res.data.affected_rows}行,耗时${res.data.cast}秒`)
        getList()
      })
      
    }
    const syncUpdateData = () => {
      root.$axios.get(`/category/siteSearch/syncUpdateData`)
      .then(res=>{
        root.$message.success(`影响行数：${res.data.affected_rows}行,耗时${res.data.cast}秒`)
        getList()
      })
      
    }
    const id = ref(0)
    const getId = data => {
      id.value = data
    }
    const handle = async (data) => {
      try {
        const arr = []
        data.forEach((item,i) => {
          arr.push({ id: item.id, sort: i+1 })
        });
        const newIndex = ref(0)
        for(let i =0;i<arr.length;i++) {
          if(arr[i].id == id.value) {
            newIndex.value = i
          }
        }
        const obj = reactive({
          prev_id: 0,
          self_id: id.value,
          next_id: 0
        })
        if(newIndex.value == 0) {
          obj.next_id = arr[1].id
        } else if(newIndex.value == (arr.length-1)) {
          obj.prev_id = arr[arr.length-2].id
        } else {
          obj.prev_id = arr[newIndex.value-1].id
          obj.next_id = arr[newIndex.value+1].id
        }
        await root.$axios.put('/category/siteSearch/dragSort',obj)
        getList()
      } catch (error) {
        getList()
        root.$message.error(error)
      }
    }
    const handleCurrentChange = e => {
      params.page = e
      getList()
    }
    const handleSizeChange = e => {
      params.per_page = e
      params.page = 1
      getList()
    }
    return {
      params,
      header,
      list,
      categoryDetail,
      show,
      siteList,
      edit,
      editTrans,
      cancel,
      confirm,
      handleSizeChange,
      handleCurrentChange,
      getId,
      handle,
      getList,
      syncInsertData,
      syncUpdateData
    }
  },
})
</script>
<style lang="scss" scoped>
.header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  div{
    margin-right: 15px;
  }
}
.control {
  display: flex;
  span {
    margin-right: 14px;
    cursor: pointer;
    font-size: 14px;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
  }
}
.site-img{
  width: 100%;
  max-height: 120px;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>