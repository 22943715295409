<template>
  <div>
    <div class="header">
      <div>
        <el-select v-model="params.site_id" class="el-select" @change="getCategory">
          <el-option
            v-for="item in siteList"
            :key="item.site_id"
            :value="item.site_id"
            :label="item.proxy_name"
          ></el-option>
        </el-select>
        <el-select v-model="params.parent_key" placeholder="请选择一级分类" filterable clearable>
          <el-option v-for="item in firstList" :key="item.value" :value="item.value" :label="item.label"></el-option>
        </el-select>
        <el-button type="primary" @click="getList">查询</el-button>
      </div>
      <!-- <el-button class="right-btn" type="primary" @click="edit(null)">添加分类</el-button> -->
    </div>
    <DragTable :header="header" :list-query="list" :sort="true" @change="handle" @start-id="getId">
      <template #site="{row}">{{row.site.proxy_name}}</template>
      <template #oneName="{row}">{{row.parent.name}}</template>
      <template #trans="{row}">
        <el-input v-model="row.trans" @blur="editTrans(row)" />
      </template>
      <template #status="{row}">
        <el-switch v-model="row.status" @change="confirm(row)"></el-switch>{{row.status || (row.status == 1)?'开':'关'}}
      </template>
      <template #control="{row}">
        <div class="control">
          <span @click="edit(row)">编辑</span>
        </div>
      </template>
    </DragTable>
    <go-dialog v-model="show">
      <div>
        <h3>原站二级分类</h3>
        <p>{{categoryDetail.name}}</p>
        <h3>翻译</h3>
        <el-input v-model="categoryDetail.trans"  placeholder="请输入翻译文本"></el-input>
        <h3>搜索开关</h3>
        <el-switch v-model="categoryDetail.status"></el-switch>
      </div>
      <footer style="display:flex;justify-content: space-between;margin-top:10px;">
        <el-button type="primary" @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'

export default defineComponent({
  setup(props,{ root }) {
    const categoryDetail = ref({})
    const show = ref(false)
    const firstList = ref([])
    const siteList = ref([]);
    const params = reactive({
      level: 2,
      parent_key: '',
      site_id: 19
    })
    const header = [
      { name: "序号", value: "id" },
      { name: "站点", slot: "site" },
      { name: "源站一级分类", slot: "oneName" },
      { name: "源站二级分类", value: "name" },
      { name: "翻译", slot: "trans" },
      { name: "搜索开关", slot: "status" },
      { name: "操作", slot: 'control', width: 80 },
    ]
    const list = reactive({data: []})
    watch(show,val=>{
      if(!val) {
        getList()
      }
    })
    const getSite = () => {
      root.$axios
        .get("/orderSafe/platformBan/site/list")
        .then((res) => {
          siteList.value = res.data;
        })
        
    };
    getSite();
    const getCategory = () => {
      root.$axios.get(`/category/siteSearch/allCategory`,{
        params: {
          site_id: params.site_id,
          level: 1,
        }
      })
      .then(res=>{
        firstList.value = res.data
      })
      
    }
    getCategory(0)
    const getList = () => {
      if(!params.parent_key) {
        root.$message.warning('前选择一级分类')
        return
      }
      root.$axios.get('/category/siteSearch/index', {
        params
      })
      .then(res=>{
        res.data.forEach(item=>{
          item.status = item.status == 1
        })
        list.data = res.data
      })
      
    }
    const edit = data => {
      data.status = data.status == 1
      categoryDetail.value = data || {}
      show.value = true
    }
    const cancel = async ()=>{
      categoryDetail.value = {}
      show.value = false
    }
    const confirm = data => {
      const id = data && data.id || categoryDetail.value.id
      const status = data && data.status || categoryDetail.value.status
      const trans = data && data.trans || categoryDetail.value.trans
      root.$axios.patch(`/category/siteSearch/update`,{
        id,
        status: status ? 1:2,
        trans
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
        cancel()
      })
      
    }
    const editTrans = data => {
      root.$axios.patch(`/category/siteSearch/update`,{
        id: data.id,
        status: data.status ? 1:2,
        trans: data.trans
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
      
    }
    const id = ref(0)
    const getId = data => {
      id.value = data
    }
    const handle = async (data) => {
      try {
        const arr = []
        data.forEach((item,i) => {
          arr.push({ id: item.id, sort: i+1 })
        });
        const newIndex = ref(0)
        for(let i =0;i<arr.length;i++) {
          if(arr[i].id == id.value) {
            newIndex.value = i
          }
        }
        const obj = reactive({
          prev_id: arr[newIndex.value-1].id,
          self_id: id.value,
          next_id: arr[newIndex.value+1].id
        })
        if(newIndex.value == 0) {
          obj.next_id = arr[1].id
        }
        if(newIndex.value == (arr.length-1)) {
          obj.prev_id = arr[arr.length-2].id
        }
        await root.$axios.put('/category/siteSearch/dragSort',obj)
        getList()
      } catch (error) {
        getList()
        root.$message.error(error)
      }
    }
    const handleCurrentChange = e => {
      params.page = e
      getList()
    }
    const handleSizeChange = e => {
      params.per_page = e
      params.page = 1
      getList()
    }
    return {
      params,
      header,
      list,
      categoryDetail,
      show,
      firstList,
      siteList,
      cancel,
      confirm,
      edit,
      editTrans,
      handleSizeChange,
      handleCurrentChange,
      handle,
      getId,
      getList,
      getCategory
    }
  },
})
</script>
<style lang="scss" scoped>
.header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  div{
    margin-right: 15px;
  }
}
.control {
  display: flex;
  span {
    margin-right: 14px;
    cursor: pointer;
    font-size: 14px;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
  }
}
.site-img{
  width: 100%;
  max-height: 120px;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>