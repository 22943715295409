<template>
  <div>
    <header class="header">
      <el-tabs v-model="active">
        <el-tab-pane label="一级分类" name="1">
        </el-tab-pane>
        <el-tab-pane label="二级分类" name="2">
        </el-tab-pane>
      </el-tabs>
    </header>
    <first ref="demo1" v-if="active == 1"></first>
    <second ref="demo2" v-if="active == 2"></second>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import first from './components/first.vue'
import Second from './components/second.vue'

export default defineComponent({
  components: { first, Second },
  setup() {
    const active = ref('1')
    return {
      active,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__header{
  margin-bottom: 0;
}
::v-deep .el-tabs__nav-wrap::after{
  bottom: -1px;
}
.header{
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #E4E7ED;
  margin-bottom: 15px;
}
.grant-input {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 84px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #7a7c81;
  line-height: 32px;
  margin-right: 15px;
  background-color: $mainColor;
  div {
    font-size: 14px;
    color: #fff;
  }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
</style>
